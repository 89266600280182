import React from "react"
import { connect } from "react-redux"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import printer from "src/images/pages/printing/printer.jpg"
import plotter from "src/images/pages/printing/plotter.png"
import tablica from "src/images/pages/printing/render5.png"


const Printing = ({isMobile}) => (
  <Layout>
    <SEO title="Wydruki wielkoformatowe - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]}
         lang="pl"/>
    <GradientsNew activeGradient={4} iconBg={divisions.printers.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "WYDRUKI WIELKOFORMATOWE",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Tablice informacyjne", id: "tablice" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Rozmiar nie ma znaczenia",
        img: plotter,
        texts: {
          2: {
            title: "DRUK NA KAŻDEJ POWIERZCHNI",
            desc: "W swoim praku maszyn posiadamy specjalistyczne maszyny pozwalające zadrukować każdą płaską powierzchnię, " +
            "dzięki temu możemy wykonać wiele spersonalizowanych gadżetów",
          },
          1: {
            title: "",
            desc: "Jesteśmy gotowi na każde zamówienie, w związku z tym nasze maszyny są przygotowane na naprawdę " +
            "duże formaty.",
          },
        },
      }}/>
      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")}>
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + printer + ")",
          backgroundPosition: "top left",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Wydruki wielkoformatowe</div>
          <div className={subpageStyles.text}>
            Są nieodłącznym elementem, reklamy tradycyjnej w której skład wchodzą chociażby banery, szyldy reklamowe,
            rollupy, reklamy wyklejane czy chociażby samochodowe
          </div>
        </div>
      </div>

      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>

      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="tablice">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + tablica + ")",
          backgroundPosition: "top center",
          backgroundSize: "auto 100%",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Tablice informacyjne<br/>Tablice BHP</div>
          <div className={subpageStyles.text}>
            Są nieodłącznym elementem, reklamy tradycyjnej w której skład wchodzą chociażby banery, szyldy reklamowe,
            rollupy, reklamy wyklejane czy chociażby samochodowe
          </div>
        </div>
      </div>

      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>

      <PriceList id="cennik" content={{
        items: [
          { title: "Wydruk UV", desc: "wycena indywidualna", price: "- zł", prefix: "od" },
          { title: "Wydruk wielkoformatowy", desc: "wycena indywidualna", price: "- zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Printing)
